.loader-container {
  width: 100vw;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.animatedLogo {
  animation-name: fadeInOut;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-out;
}

@keyframes fadeInOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
